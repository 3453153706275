@media only screen and (min-width: 1200px) and (max-width: 1299px) {
    .broker-table.record-update th:first-child,
    table.broker-table.update-missing th:first-child {
      width: 155px;
    }
    .broker-table.record-update th:nth-child(4),
    table.broker-table.update-missing th:nth-child(4) {
      width: 100px;
    }
    .project-detail-heading-table th:nth-child(1) {
      width: 360px;
    }
    .project-detail-inner-table td:nth-child(2) {
      width: 332px;
    }
    .maxWidth90Per {
      max-width: 85%;
    }
  }

  @media only screen and (max-width: 1200px) {
    .modal-dialog.plans-modal {
      max-width: 95%;
    }
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .management-border {
      width: fit-content;
    }
    .card.start-bid {
      padding: 10px 12px 10px 12px;
    }
    .mr_top_ipad {
      margin-top: 15px;
    }
    table.flagged-table.table th {
      width: 220px;
    }
    .update-record-scroll,
    .updated-missing-scroll,
    .flagged-records-scroll,
    .management-scroll {
      overflow-x: auto;
    }
    .broker-table.record-update th,
    table.broker-table.update-missing th {
      width: 160px;
    }
    .broker-table.record-update th:nth-child(4),
    table.broker-table.update-missing th:nth-child(4) {
      width: 100px;
    }
    .broker-table.record-update th:last-child {
      width: 155px;
    }
    table.broker-table.update-missing th:first-child {
      width: 160px;
    }
    table.broker-table.update-missing th:last-child {
      width: 260px;
    }
    .pagination-dots,
    .pagination-records {
      margin: auto;
    }
    table.flagged-table.table thead th:first-child {
      width: 170px;
    }
    table.flagged-table.table thead th:nth-child(2) {
      width: 220px;
    }
    table.project-table {
      table-layout: fixed;
    }
    table.project-table thead th {
      width: 200px;
    }
    .drawer {
      width: 50%;
    }
    .collapse-selected-table table.table {
      table-layout: fixed;
    }
    .collapse-selected-table th.expand-cell-header {
      max-width: 35px;
    }
    .collapse-selected-table th {
      width: 142px;
    }
  }
  
  @media only screen and (min-width: 576px) and (max-width: 991px) {
    .management-border {
      width: fit-content;
    }
    .content-header-row .back-btn {
      font-size: 14px;
    }
    .remaining-brokers .collapse {
      padding: 8px 8px 0px 8px;
    }
    table.project-table {
      table-layout: fixed;
    }
    table.project-table thead th {
      width: 200px;
    }
    table.flagged-table.table thead th:nth-child(2) {
      width: 220px;
    }
    table.flagged-table.table thead th:first-child {
      width: 170px;
    }
    table.flagged-table.table th {
      width: 220px;
    }
    .update-record-scroll,
    .updated-missing-scroll,
    .flagged-records-scroll,
    .management-scroll {
      overflow-x: auto;
    }
    .broker-table.record-update th,
    table.broker-table.update-missing th {
      width: 160px;
    }
    .broker-table.record-update th:nth-child(4),
    table.broker-table.update-missing th:nth-child(4) {
      width: 100px;
    }
    .broker-table.record-update th:last-child {
      width: 155px;
    }
    table.broker-table.update-missing th:first-child {
      width: 160px;
    }
    .pagination-dots,
    .pagination-records {
      margin: auto;
      text-align: center;
    }
    .broker-top-space {
      padding-top: 35px;
    }
    .drawer {
      width: 50%;
    }
    .collapse-selected-table table.table {
      table-layout: fixed;
    }
    .collapse-selected-table th.expand-cell-header {
      max-width: 35px;
    }
    .collapse-selected-table th {
      width: 142px;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .pilot-project-select {
      width: 100%;
      order: 1;
      margin: 10px 0;
    }
  }
  
