@import './variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  font-size: 13px !important;
  font-family: Heebo, sans-serif !important;
  background: #f9f9fb !important;
  color: #383838;
}

::-webkit-scrollbar {
  width: 12px;
}

// ::-webkit-scrollbar-track {
//   background: $scrollbar-color;
// }

::-webkit-scrollbar-thumb {
  background-color: $gray;
  border-radius: 20px;
  border: 3px solid $scrollbar-color;
}


@mixin show-ellipses-mixin {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

@for $i from 8 through 20 {
  .font-size-#{$i} {
    font-size: #{$i}px !important;
  }
}

/****** left sidebar css ******/
.sidebar-quad {
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 296px;
  position: fixed;
  min-height: 100vh;
  background: $grey-800;

  .sidbar-logos {
    padding-top: 9px;
    padding-bottom: 10px;
  }
}

.form-group label {
  text-align: left;
  font-size: 13px;
  font-family: Heebo;
  letter-spacing: 0px;
  margin-bottom: 6px;
}

/****** right side content part css ******/

.avatar {
  font-size: 1rem;
  display: inline-flex;
  width: 40px;
  height: 40px;
  color: $white;
  border-radius: $border-radius;
  background-color: #adb5bd;
  align-items: center;
  justify-content: center;
}

.avatar.rounded-circle img,
.rounded-circle {
  border-radius: $border-radius;
}

.avatar img {
  width: 100%;
  border-radius: $border-radius;
}

.content-header-row h6,
.content-header-row .back-btn {
  font-size: 16px;
  font-weight: 600;
  color: #484554;

  &:hover {
    text-decoration: none;
    color: #312f2d;
  }
}

.video-review-head {
  .content-header-row {
    h6 {
      position: relative;
      top: 3px;
    }
  }
}

.no-result-message {
  color: #878491;
  font-weight: 400;
  font-size: 22px;
}

/****** Property listing page css ******/

.card-detail-highlighter {
  background: #f4f4f4;
  border-radius: $border-radius;
  padding: 15px;
}

.property-card-detail {
  border: 0 !important;
}

.property-card-detail h6 {
  font-size: 14px;
}

.card-detail-highlighter {
  margin-top: 2px;
}

.property-card-detail .text-muted {
  color: #878491;
  margin-bottom: 3px;
  text-transform: uppercase;
  font-size: 13px;
}

.card-detail-highlighter .text-muted {
  color: #7b798d;
}

.property-card-detail p.assets-price-quad {
  font-size: 14px;
  font-weight: 500;
}

.property-card-row {
  border: solid 1px transparent;
  border-top-color: #edf0f2;

  &:hover {
    border-color: $purple-6;
  }
}

.property-card-detail .property-image {
  width: 100%;
  height: 135px;
  object-fit: cover;
}

/****** Property detail page css ******/
.property-detail-tabs {
  background: #e9e7f5;
}

.property-detail-tabs h6 {
  line-height: 70px;
  margin: 0 10px 0 25px;
}

.property-detail-tabs .nav-item+.nav-item {
  border-top: solid 1px #d8d7db;
}

.property-detail-tabs .nav-link {
  font-size: 13px;
  font-weight: 700;
  color: #383838;
  padding: 10px 32px;
  background: #c7ccd1;
  border-bottom: 1px solid #f1f1f1;
}

.property-detail-tabs .nav-link.active {
  background: $purple-1;
  color: $white;
}

.property-detail-page .card-body {
  padding: 16px;
}

.property-detail-page .card-title {
  font-size: 16px;
  color: #1f1f1f;
}

.property-detail-page .card-subtitle {
  color: #3d3b4b !important;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 11px;
}

.property-detail-page .property-card-detail h6 {
  margin-bottom: 28px;
  font-size: 16px;
}

.property-detail-page .property-card-detail h5 {
  font-weight: 700;
  font-size: 14px;
}

.border-left-gray {
  border-left: solid 1px #0000001a;
}

.back-icon {
  font-size: 11px;
  position: relative;
  top: -1px;
  margin-right: 8px;
  color: $black-300;
}

/****** Customized bootstrap css ******/
.btn-primary {
  background-color: $primary !important;
  border-color: $primary !important;
}

.font-weight-500 {
  font-weight: $font-weight-500;
}

.card.start-bid {
  background-color: #0077db;
  color: $white;
  padding: 10px 36px 10px 12px;
}

.color-inherit,
.color-inherit:hover {
  color: inherit;
}

.border-radius-8px {
  border-radius: $border-radius-8px !important;
}

.card.grey-card {
  background-color: $gray-700;
}

.font-weight-600 {
  font-weight: 600;
}

.dotted-border {
  border-bottom: 2px dotted #dee2e6;
}

.dotted-border-top {
  border-top: 2px dotted #dee2e6;
}

.main-card-property.card {

  .btn.disabled,
  .btn:disabled {
    opacity: 0.8;
  }

  background: #f9f9fb;
  border: 0;
}

.main-card-property.card .starting-bid {
  background: #f2f0f8;
}

.plan-selector {
  min-height: 101px;
}

.plan-selector input.form-control,
.plan-selector span.form-control {
  background-color: #d4d8dd;
  font-weight: bold;
  font-size: 14px;
  color: #1f1f1f;
}

.modal-heading-check h5 {
  font-size: 20px;
  font-weight: 700;
}

button.previous-btn {
  color: $purple-1;
  border-color: $purple-1;
  background-color: transparent;
}

button.save-btn {
  color: $purple-1;
  background-color: #e4e3ee;
  border-color: #e4e3ee;
  font-weight: 500;
}

span.side-border {
  height: 25px;
  display: inline-block;
  width: 1px;
  background: #c7ccd1;
  margin: 0 15px;
  vertical-align: middle;
}

button.add-more-btn.btn {
  outline: unset;
  box-shadow: unset;
  padding: 0;
  text-decoration: unset;
  font-weight: 600;
  font-size: 14px;
}

.example-div {
  background: #f5f5f5;
  border-radius: 4px;
  padding: 10px 10px;
}

.example-div span {
  color: #858585;
  font-size: 14px;
  font-weight: 600;
}

.example-div p {
  color: #525252;
  font-weight: 700;
  font-size: 16px;
}

.call-action-field {
  min-height: 60px;
}

.summery-heading {
  color: #3d3b4b;
}

.badge-light.filter-badge {
  font-size: 13px;
  border-radius: 8px;
  font-weight: 500;
  padding: 0 10px;
  line-height: 32px;
  color: #3d3b4b;
  margin-bottom: 8px;
  background: #f3f2f7;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
}

.badge-light.filter-badge button.close {
  vertical-align: middle;
  margin-left: 10px;
  font-size: 21px;
  font-weight: 400;
  line-height: 30px;
  color: #484554;
  opacity: 1;
  display: inline-block;
}

.add-heading {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
}

.order-heading {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 12px;
}

.order-table .col-sm-7 {
  font-size: 12px;
  font-weight: 500;
}

.order-table .col-sm-5 {
  font-size: 14px;
  font-weight: 500;
}

.order-red .col-sm-7,
.order-red .col-sm-5 {
  margin-bottom: 40px;
  font-size: 16px;
  color: #e94853;
  font-weight: 600;
}

button.proceed-btn {
  background-color: $purple-1;
  font-weight: 500;
  padding: 11px 0;
  border-radius: 8px;
}

.btn.view-detail-btn {
  outline: none;
  box-shadow: none;
}

table.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f8fc;
}

strong.platnium-price, strong.silver-price, strong.gold-price, strong.bronze-price {
  font-weight: 500;
  color: $black;
  font-size: 16px;
}

.close-btn-detail-plan.modal-header button.close {
  font-size: 28px;
}

.text-purple {
  color: $purple-1 !important;
}

.btn-primary:hover,
button.proceed-btn:hover {
  background-color: transparent !important;
  border-color: $purple-1 !important;
  color: $purple-1 !important;
}

button.previous-btn:hover {
  color: $white;
  background-color: $purple-1;
  border-color: $purple-1;
}

button.save-btn:hover {
  color: $purple-1;
  background: #c7ccd1;
  border-color: #c7ccd1;
}

.content-quad {
  z-index: 3;
  position: relative;
  margin-left: 296px;
  padding-top: 57px;
  width: calc(100% - 296px) !important;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);

  .content-header-row {
    position: fixed;
    left: 320px;
    top: 0;
    right: 0;
    background-color: #f9f9fb !important;
    z-index: 1;
    box-shadow: 2px 1px 6px rgba(0, 0, 0, 0.1);
    border-bottom: 0px !important;
  }
}

.prop-list-card {
  cursor: pointer;
}

.prop-list-card:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.property-caption-parent {
  position: relative;
}

.property-caption-child {
  position: absolute;
  bottom: 0;
  padding: 0 15px;
  color: $white;
  left: 0;
  background: transparent linear-gradient(180deg, #00000000 0%, #00000000 65%, #000000b4 100%);
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    font-size: 14px;
    margin-bottom: 10px;
  }

  h5 {
    font-size: 20px;
    margin-top: auto;
  }
}

button.unfinished-alert {
  color: #e94853;
  background-color: #fce8ea;
  font-size: 14px;
}

button.unfinished-alert:hover {
  color: #e94853;
  background-color: #fce8ea;
}

body .text-muted {
  color: #878491 !important;
}

.user-dd {
  padding: 0;

  span.user-circle {
    display: inline-block;
  }

  p {
    @include show-ellipses-mixin;
    font-size: 14px;
    max-width: 10rem;
    display: inline-block;
  }

  h4 {
    @include show-ellipses-mixin;
    max-width: 10rem;
    font-size: 14px;
  }

  button {
    font-size: 14px;
    padding: 5px 15px;
  }
}

.react-time-picker .react-time-picker__wrapper,
.react-time-picker .react-time-picker__wrapper select {
  border: transparent;
  color: #495057;
  margin-right: 5px;
}

.react-time-picker .react-time-picker__wrapper svg {
  stroke: #495057;
}

.form-control {
  color: #495057 !important;
  font-size: 14px;
  background-image: none;
}

.form-control::placeholder {
  color: #a0a0a0 !important;
  font-size: 14px;
}

.prop-list-card h5 {
  color: #1f1f1f;
}

.plans-modal table.table-bordered {
  margin-bottom: 0;
}

.border-bottom {
  border-color: #c6c3da !important;
}

.modal .modal-content {
  border-radius: 8px;
}

.summery-add-property {
  display: flex;
  flex-direction: column;
}

.summery-add-property p {
  margin-bottom: 0 !important;
}

.h-40 {
  height: 40px;
}

.react-time-picker .react-time-picker__wrapper select:focus-visible,
.react-time-picker__inputGroup__input:focus-visible {
  outline: none;
}

button.react-time-picker__clear-button.react-time-picker__button {
  display: none;
}

.paginator {
  .prev-next-btn {
    svg {
      color: #808080;
    }

    &:hover {
      svg {
        color: #212529;
      }
    }
  }
}

.property-list {
  min-height: calc(100vh - 150px);
  height: auto;
}

/****** Plans Modal css ******/

.plans-table {
  .selected{
    .plan-header{
      background: transparent linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 59%, #D6D3EFB2 100%) 0% 0% no-repeat padding-box;
    }
  }
  .plan-header {
    min-height: 200px;
  }

  .plans-col {
    border-right: 0;
    font-weight: 400;
    border-bottom: 0;
    border-top: 0;
  }

  .features-container {
    .feature-row {
      line-height: 44px;
    }
  }

  .customizable {
    background: #fffcfc;
    font-weight: 700;
    padding: 10px 0.75rem;
  }

  .plan-footer {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.btn {
  line-height: 1.4;
}

.plan-header.platnium-plan h5 {
  font-size: 16px;
  font-weight: 700;
  color: #7663c3;
}

.paginator.btn-group button {
  background: transparent;
  color: #808080;
  border: 0;
  font-weight: 500;

  &:hover {
    color: #212529;
  }
}

.paginator.btn-group button.btn-secondary:not(:disabled):not(.disabled).active {
  color: #212529;
  background-color: transparent;
  border-color: transparent;
}
.modal-dialog.plans-modal {
  max-width: 1100px;
}
.plans-modal .modal-header {
  border-bottom: 0;
}

span.price-tag {
  display: inline-block;
  width: 80px;
  margin-right: 10px;
}

.plan-header {
  background: $white;
}

.plans-table .plans-col {
  border: 1px solid #D8D8D8;
  border-radius: 12px;
  overflow: hidden;
  &.selected{
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 59%, #D6D3EFB2 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 12px #00000029;
  border: 3px solid #9792C6;
  border-radius: 15px;
  }
}

.plans-features-headings {
  border: 2px solid transparent;
  border-radius: 25px;
  overflow: hidden;
}

.plan-header.platinum-plan h5 {
  border-radius: 12px 12px 0 0;
  background: transparent linear-gradient(90deg, #1F69C9 0%, #285EA7 100%) 0% 0% no-repeat padding-box;
  text-transform: uppercase;
  color: #ffffff;
  padding: 15px 0;
  margin-bottom: 0;
  font-size:14px;
  height: 44px;
}

.plan-header.gold-plan h5 {
  border-radius: 12px 12px 0 0;
  background: transparent linear-gradient(90deg, #E5C646 0%, #C7A721 100%) 0% 0% no-repeat padding-box;
  text-transform: uppercase;
  color: #ffffff;
  padding: 15px 0;
  margin-bottom: 0;
  font-size:14px;
  height: 44px;
}

.plan-header.silver-plan h5 {
  border-radius: 12px 12px 0 0;
  background: transparent linear-gradient(90deg, #C2C8D0 0%, #BCC7D9 100%) 0% 0% no-repeat padding-box;
  text-transform: uppercase;
  color: #ffffff;
  padding: 15px 0;
  margin-bottom: 0;
  font-size:14px;
  height: 44px;
}

.plan-header.bronze-plan h5 {
  border-radius: 12px 12px 0 0;
  background: transparent linear-gradient(90deg, #DBC3B1 0%, #BC9B89 100%) 0% 0% no-repeat padding-box;
  text-transform: uppercase;
  color: #ffffff;
  padding: 15px 0;
  margin-bottom: 0;
  font-size:14px;
  height: 44px;
}

.plan-header.tombstone-plan h5 {
  border-radius: 12px 12px 0 0;
  background: transparent linear-gradient(90deg, #CBDBB1 0%, #A4BC89 100%) 0% 0% no-repeat padding-box;
  text-transform: uppercase;
  color: #ffffff;
  padding: 15px 0;
  margin-bottom: 0;
  font-size:14px;
  height: 44px;
}

.plans-table .platinum-col-plan .features-container .feature-row:nth-child(even) {
  background-color: #f7f5ff;
}

.plans-table .gold-col-plan .features-container .feature-row:nth-child(even) {
  background-color: #fff5e4;
}

.plans-table .silver-col-plan .features-container .feature-row:nth-child(even) {
  background-color: #f9f9f9;
}

.plans-table .plans-features-headings .features-container .feature-row:nth-child(even) {
  background-color: #fafafa;
}

.header-radius {
  overflow: hidden;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.unfinished-orders-modal h5 {
  font-size: 18px;
}

.unfinished-orders-modal .modal-header {
  background: #e4e3ee;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.broker-card-body .col-lg-auto {
  min-width: 50%;
}

.broker-table thead th {
  background-color: #565082cc;
  color: $white;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0.35rem 0.75rem;
}

.broker-table tbody td {
  color: #18171c;
  padding: 7px 8px;
  vertical-align: middle;
  font-size: 14px;
  line-height: 31px;
  border-color: #d5d3e3;
}

.broker-table tbody tr.light-red td {
  background-color: #e948531a;
}

.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 14px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch .slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 0;
  bottom: -3px;
  background-color: #f1f1f1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch input:checked+.slider {
  background-color: #77729a;
}

.switch input:focus+.slider {
  box-shadow: 0 0 1px #77729a;
}

.switch input:checked+.slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.switch .slider.round {
  border-radius: 16px;
}

.switch .slider.round:before {
  border-radius: 50%;
}

.broker-table input {
  margin-bottom: 0;
  height: 32px;
  box-shadow: unset !important;
  font-size: 14px;
}

.broker-table .input-group .form-group {
  margin-bottom: 0;
}

.react-bootstrap-table-pagination-list ul {
  justify-content: flex-end;
}

span.user-circle {
  background: #e4e3ee;
  padding: 6px 10px;
}

.bg-light-success {
  background-color: #6ece5c33;
}

.bg-purple-dark {
  background-color: #565374;
}

.cursor-pointer {
  cursor: pointer;
}

/* ----------- detail-drawer ----------- */

.fade .right-drawer-transition.modal-dialog {
  -webkit-transform: translate(100%, 0) scale(1);
  transform: translate(100%, 0) scale(1);
}

.show .right-drawer-transition.modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  display: flex;
  align-items: stretch;
  -webkit-box-align: stretch;
  height: 100%;
  max-width: 100% !important;
  box-shadow: 0rem 0.2rem 0.4rem 0rem rgba(0, 0, 0, 0.3);
}

.notification-modal .modal {
  top: 0;
  right: 0;
  left: unset;
}

.right-drawer-transition .modal-content {
  border-radius: 0;
  border: 0;
}

.detail-drawer .modal-body {
  max-height: calc(100vh);
  overflow-y: auto;
}

.detail-drawer.modal-dialog {
  max-width: inherit;
  width: calc(100vw - 250px);
  height: 100vh;
  margin: 0;
  margin-left: auto;
}

.detail-drawer-width-420.modal-dialog {
  width: 423px;
}

span.not-started {
  padding: 5px 8px;
  background: #dae3d5;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  font-size: 12px;
}

span.active-td {
  padding: 5px 8px;
  background: #fdf4e8;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  color: #f09d2e;
  font-size: 12px;
}
.completed-td{
  padding: 5px 8px;
  background: #28a74547;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  color: #28a745;
  font-size: 12px;
}

table.project-table td {
  font-size: 14px;
  padding: 0.75rem 0.55rem;
}

.chat-span {
  position: relative;
}

.chat-span>span {
  position: absolute;
  right: -5px;
  top: -4px;
  font-size: 10px;
  background: #f56464;
  padding: 0px 5px;
  color: $white;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  text-align: center;
  display: inline-block;
}

.bell-span {
  position: relative;
}

.bell-span>span {
  position: absolute;
  right: -5px;
  top: -4px;
  font-size: 10px;
  background: #f56464;
  padding: 0px 5px;
  color: $white;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  text-align: center;
  display: inline-block;
}

.attention-span {
  position: relative;
}

.attention-span>span {
  position: absolute;
  right: 3px;
  top: -2px;
  font-size: 10px;
  background: #f5b864;
  padding: 0px 3px;
  color: $white;
  border-radius: 50%;
  height: 20px;
  max-width: 50px;
  text-align: center;
  display: inline-block;
  line-height: 20px;
  z-index: 111;
  min-width: 20px;

  &.small-circle {
    top: 6px;
    width: 8px;
    height: 8px;
    right: 14px;
    min-width: auto;
    max-width: none;
    background: #f0850e;
  }
}

.management-tabs .tab-ul {
  color: $white;
}

.management-tabs .tab-ul a {
  font-size: 13px;
  padding: 10px 30px;
  border-bottom: 1px solid #dedede;
}

.management-tabs .tab-ul ul.nav a.active {
  background: $purple-shade;
  color: $white;
  font-weight: 600;
}

.management-tabs .tab-ul ul.nav a {
  // padding: 10px 50px;
}

.portal-dropdown {
  background: $white;
  border: 1px solid $purple-shade;
  border-radius: 4px;
  width: 75%;
}

.portal-dropdown button {
  background: transparent;
  color: $purple-1;
  border: 0;
  width: 100%;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  padding: 7px 15px;
}

.portal-dropdown {
  margin-bottom: 20px;
}

.portal-dropdown .dropdown-menu {
  width: 100%;
  padding: 0;
}

.portal-dropdown .dropdown-divider {
  margin: 1px 0;
}

.plan-describe {
  display: flex;
  min-height: 225px;
  padding: 15px;
  font-size: 12px;
  border-bottom: 1px solid #D8D8D8;
}

strong.platinum-price {
  font-weight: 500;
  color: $black;
  font-size: 16px;
}

.collapse-section button.btn {
  font-size: 18px;
  color: #000000;
  background: transparent;
  font-weight: 400;
  text-align: left;
  padding: 12px 25px;
  border-radius: 0;
  text-decoration: unset;
}

.collapse-section table table {
  margin: 0;
  border: 0;
  background: #f2f2f7;
}

td.reset-expansion-style {
  padding: 0;
}

.collapse-section table table td {
  border-bottom: 2px dotted #e4e3e8;
  border-top: 0;
  font-size: 13px;
}

.collapse-section table table td {
  font-weight: 400;
}

.collapse-section table table tr:last-child td,
.collapse-section table table tr:last-child {
  border-bottom: 0;
}

.collapse-section table table tr {
  border-top: 0;
}

.collapse-section thead {
  display: none;
}

.collapse-selected-table {
  margin: 15px 0;
}

.collapse-selected-table thead {
  background: #c8c7d2;
  color: #302e38;
}

.collapse-selected-table thead th {
  padding: 6px 0.75rem;
  text-transform: uppercase;
  font-size: 12px;
  border: 0;
}

.collapse-selected-table table {
  border-bottom: 1px solid #c8c7d2;
}

.collapse-selected-table tbody>tr {
  font-weight: 500;
  background: #f0f0f5;
}

tr.parent-expand-bar {
  background: #ecebf3;
}

.collapse-selected-table td.reset-expansion-style {
  border: 0;
}

.project-collapse-detail {
  font-weight: normal;
}

span.complete-badge {
  background: #eaf3e6;
  color: #64bc26;
  display: inline-block;
  padding: 3px 9px;
  border-radius: 8px;
  border: 1px solid #64bc263d;
  font-size: 13px;
  text-transform: uppercase;
}

span.delay-badge {
  background: #f3e7e6;
  color: #bc2626;
  display: inline-block;
  padding: 3px 9px;
  border-radius: 8px;
  border: 1px solid #bc26263d;
  font-size: 13px;
  text-transform: uppercase;
}

span.delay-badge {
  background: #f3e7e6;
  color: #bc2626;
  display: inline-block;
  padding: 3px 9px;
  border-radius: 8px;
  border: 1px solid #bc26263d;
  font-size: 13px;
  text-transform: uppercase;
}

.collapse-selected-table th.expand-cell-header {
  opacity: 0;
  max-width: 35px;
}

.collapse-section table {
  table-layout: fixed;
}

table.project-table thead {
  color: #ededed;
  background: #565374;
  text-transform: uppercase;
}

table.project-table thead th {
  font-weight: 500;
  font-size: 13px;
  padding: 7px 0.75rem;
  white-space: nowrap;
}

table.project-table {
  background: $white;
}

table.project-table tr:last-child td {
  border-bottom: 1px solid #e4e3e8;
}

.react-bootstrap-table-pagination-list ul.pagination {
  justify-content: flex-end;
}

.react-bootstrap-table-pagination li.page-item .page-link {
  background: transparent;
  color: #808080;
  border: 0;
  font-weight: 600;
  border: 0;
  font-size: 15px;
}

.react-bootstrap-table-pagination .page-item.active .page-link {
  z-index: 3;
  color: #212529;
  background-color: transparent;
  border-color: transparent;
}

.react-bootstrap-table-pagination span.react-bs-table-sizePerPage-dropdown.dropdown button {
  background: transparent;
  color: #212529;
  border: 0;
  box-shadow: unset !important;
  outline: unset !important;
}

table.project-table tr,
.broker-table tr {
  cursor: pointer;
}

.border-left-3 {
  border-left: 3px solid #e4e3e8;
}

.custom-error-msg {
  font-size: 12px;
  color: #1f1f1f;
  background-color: #c329291a;
  padding: 8px 8px;
  margin-bottom: 10px;
}

#pmNotes,
#customerNotes {
  border: 0 !important;
  outline: none !important;
  box-shadow: none;
  min-height: 85px;
  background-color: $white;
}

.star {
  display: inline-block;
}

.disableStar {
  pointer-events: none;
}

.editorListTable th.ascending::after {
  content: '<';
  display: inline-block;
  margin-left: 1em;
  transform-origin: center;
  transform: rotate(90deg);
}

.editorListTable th.descending::after {
  content: '>';
  display: inline-block;
  margin-left: 1em;
  transform-origin: center;
  transform: rotate(90deg);
}

.activeStep {
  background: #dedce5;
}

.download-zipBtn {
  outline: none !important;
  box-shadow: none !important;
  text-decoration: underline !important;
}

@media only screen and (max-width: 1200px) {
  .modal-dialog.plans-modal {
    max-width: 95%;
  }
}

.maxWidth1px {
  max-width: 1px;
}

.auction-selector {
  input[type='checkbox'] {
    display: inline-block;
    margin-top: 3px;
  }

  label,
  span {
    font-size: 14px;
    font-weight: 600;
  }
}

input.auction-input {
  margin-right: 6px !important;
}

.calender-icon {
  position: relative;
  top: 35px;
  right: 0;
  margin-left: -22px;
  color: #a5a5a5;
}

.maxWidth1px {
  max-width: 1px;
}

span.caret-4-desc {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-left: 2px;
  vertical-align: middle;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M13 16.172l5.364-5.364 1.414 1.414L12 20l-7.778-7.778 1.414-1.414L11 16.172V4h2v12.172z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}

span.caret-4-asc {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-left: 2px;
  vertical-align: middle;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M13 7.828V20h-2V7.828l-5.364 5.364-1.414-1.414L12 4l7.778 7.778-1.414 1.414L13 7.828z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}

.activity-log {
  color: rgba(48, 46, 56, 0.8);
}

.activity-log .status {
  color: rgb(48, 46, 56);
  font-weight: 500;
}

.activity-log .date-time {
  color: rgba(48, 46, 56, 0.5);
}

.project-image {
  width: 88px;
  height: 88px;
}

.count-btn {
  outline: 0;
  box-shadow: unset !important;
}

.project-detail-heading-table {
  border-left: 0 !important;
  border-right: 0 !important;

  thead th {
    border-bottom-width: 1px !important;
    padding: 5px 10px;
  }

  th {
    &:nth-child(1) {
      width: 445px;
      border-left: 0;
    }

    &:nth-child(2) {
      width: 245px;
    }

    &:nth-child(3) {
      width: 225px;
      text-align: center;
    }

    &:nth-child(4) {
      width: 110px;
      text-align: center;
    }

    &:last-child {
      border-right: 0;
    }
  }
}

.expand-table-container {
  border-top: 1px solid #0000001a;

  &:last-child {
    border-bottom: 1px solid #0000001a;
  }
}

.project-detail-inner-table {
  border: 0 !important;
  cursor: pointer;

  tr {
    border-bottom: 0;
  }

  th,
  td {
    border-top: 0 !important;
    border-bottom: 0 !important;
  }

  th {
    &:last-child {
      border-right: 0;
    }
  }

  td {
    padding: 10px;
    border-top: 0;
    border-bottom: 0;

    &:nth-child(1) {
      width: 15px;
      border-left: 0;
      border-right: 0;
    }

    &:nth-child(2) {
      width: 420px;
      border-left: 0;
    }

    &:nth-child(3) {
      width: 242px;
    }

    &:nth-child(4) {
      width: 220px;
      text-align: center;
    }

    &:nth-child(5) {
      width: 110px;
      text-align: center;
    }

    &:last-child {
      border-right: 0;
      width: 20%;
    }

    .badge {
      border-radius: 4px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      max-width: 200px;
    }
  }

  .reset-expansion-style {
    padding: 0 !important;
  }

  &.activity-table {
    td {
      border-bottom: 2px dotted #e4e3e8 !important;
    }
  }
}

.completed-badge {
  background: #e5fce8 0% 0% no-repeat padding-box;
  border: 1px solid #41ce5166;
  color: #41ce51;
}

.textIndent45 {
  text-indent: 45px;
}

.management-tabs .tab-ul>li.nav-item {
  border-bottom: 10px solid #e8e8e8;
}

.management-tabs .tab-ul ul.nav a form .flex-grow-1.form-group {
  margin-bottom: 0;
}

.management-tabs .tab-ul ul.nav a form .clearfix {
  display: none;
}

button.check-btn.btn.btn-secondary {
  background: transparent !important;
  border: 0;
  padding: 0 10px;
  outline: 0;
  box-shadow: unset !important;
}

.management-tabs .tab-ul ul.nav a form .flex-grow-1.form-group input {
  vertical-align: sub;
}

.broker-table tbody td a {
  color: #18171c;
  text-decoration: none;
  width: 100%;
  vertical-align: middle;
}

button.check-btn.btn.btn-secondary.disabled svg {
  color: #ccc;
}

.portal-dropdown .dropdown-toggle::after {
  right: 10px;
  top: 17px;
  position: absolute;
}

.collapse-section button.attention-button {
  border: 0;
  margin: 0;
  width: 30px;
  padding: 2px;
  height: 29px;
  position: relative;

  &:focus,
  &:hover,
  &:active {
    border: 0 !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  img {
    position: absolute;
    top: -6px;
  }
}

//.attention-box {
// position: relative;
.attention-popover {
  border: 0;
  box-shadow: none;
  // position: absolute;
  right: 0;
  z-index: 1;

  .popover {
    border: 0;
    width: 320px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    right: 0;
    left: auto;
    max-width: none;

    .popover-body {
      padding: 15px 12px;
    }
  }

  .asset-name {
    background: rgba(80, 80, 130, 0.1);
    border-radius: 4px;
    padding: 0 5px;
    font-weight: 600;
    max-width: 100%;
  }

  .action-name {
    color: #2b2626;
    font-weight: 500;
  }

  .arrow {
    display: none;
  }

  .time {
    font-size: 11px;
  }

  .close-button {
    top: -5px;
    right: 5px;
    padding: 0;
    position: absolute;
  }

  .defer-btn {
    color: #555555;
    border: solid 1px #e5e5e5;
    background: #e5e5e5;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 12px;
  }

  .yes-btn {
    background: $purple-1 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding: 5px 10px;
    text-align: center;
    color: $white;
    font-size: 12px;
  }
}

//}

.drawer {
  width: 35%;
  height: calc(100vh - 57px);
  position: fixed;
  right: -790px;
  top: 55px;
  transition: 1s;
  background: $white;
  border-left: solid 1px rgba(0, 0, 0, 0.15);
  z-index: 9;

  header {
    padding: 12px 10px;
    position: relative;
    border-bottom: solid 1px rgba(80, 80, 130, 0.25);

    h6 {
      font-size: 15px;
      font-weight: 500;
    }
  }

  .drawer-body {
    height: calc(100vh - 105px);
  }
}

.drawer.slide {
  transition: 3s;
  right: 0;
  z-index: 99999;

  .close-button {
    position: absolute;
    right: 7px;
    top: 12px;
    padding: 0;
  }
}

.notification-item {
  background-color: #fffaef;
  border-radius: 4px;
  border-bottom: 1px solid #e4e3e8;

  .asset-name {
    background: rgba(80, 80, 130, 0.1);
    border-radius: 4px;
    padding: 0 5px;
    font-weight: 600;
    margin-bottom: 10px;
    max-width: 95%;
  }

  .time {
    color: #2b2626;
    font-size: 12px;
  }

  .action-name {
    color: #2b2626;
    font-weight: 500;
  }

  .link-in-notification {
    max-width: 50%;
    line-height: 36px;
  }

  .btn {
    font-size: 12px;
  }

  .popover-body {
    position: relative;
  }

  .defer-btn {
    color: #555555;
    border: solid 1px #e5e5e5;
    background: #e5e5e5;
    border-radius: 4px;
    padding: 5px 10px;
  }

  .yes-btn {
    padding: 5px 10px;
    background: $purple-1 0% 0% no-repeat padding-box;
    border-radius: 4px;
  }
}

.broker-directory-filters {
  padding-left: 30px;
  padding-top: 10px !important;

  .form-group {
    margin-bottom: 0;
  }

  form {
    display: block;

    .customized-checkbox {
      float: left;
      clear: both;
    }
  }
}

.flagged-record-filters {
  padding-left: 30px !important;
  padding-top: 10px;
  background-color: $purple-shade;

  .form-group {
    margin-bottom: 0;
  }
}

.flagged-record-filters {
  padding-left: 50px;
  background-color: $purple-shade;

  .form-group {
    margin-bottom: 0;
  }
}

.management-tabs .tab-ul ul.nav a.flagged-details-tab~.flagged-record-filters {
  display: none;
}

.management-tabs .tab-ul ul.nav a.flagged-details-tab.active~.flagged-record-filters {
  background: $purple-shade;
  display: block;
}

.update-missing-record-row button.check-btn.update-missing-flag.btn.btn-secondary {
  opacity: 0;
}

.update-missing-record-row:hover button.check-btn.update-missing-flag.btn.btn-secondary {
  opacity: 1;
}

.additional-filter {
  color: $black-300;
  font-size: 12px !important;
}

.management-tabs .tab-ul ul.nav a.flagged-details-tab~.flagged-record-filters {
  display: none;
}

.management-tabs .tab-ul ul.nav a.flagged-details-tab.active~.flagged-record-filters {
  background: $purple-shade;
  display: block;
}

.update-missing-record-row button.check-btn.update-missing-flag.btn.btn-secondary {
  opacity: 0;
}

.update-missing-record-row:hover button.check-btn.update-missing-flag.btn.btn-secondary {
  opacity: 1;
}

.additional-filter {
  color: #bcb8da;
}

.management-tabs .tab-ul ul.nav a.missing-details-tab~.broker-directory-filters {
  display: none;
}

.management-tabs .tab-ul ul.nav a.active.missing-details-tab~.broker-directory-filters {
  background: $purple-shade;
  display: block;
}

.plan-image-placeholder {
  width: 24px;
  height: 24px;
  display: inline-block;
}

button.attention-span {
  box-shadow: unset !important;
}

.plan-image-placeholder {
  width: 24px;
  height: 24px;
  display: inline-block;
}

.update-record-row button.check-btn.record-pencil.btn.btn-secondary {
  opacity: 0;
}

.broker-table tr.update-record-row:hover button.check-btn.record-pencil.btn.btn-secondary {
  opacity: 1;
}

.broker-table tr.update-record-row:hover {
  background: #d8d7e3;
}

.broker-table tr.update-record-row button.check-btn.btn.btn-secondary.disabled svg {
  color: #554f7f;
}

th.edit-save {
  width: 100px;
}

table.broker-table.update-missing {
  table-layout: fixed;
}

table.broker-table.update-missing th:first-child {
  width: 12%;
}

table.broker-table.update-missing th:nth-child(4) {
  width: 9%;
}

table.flagged-table.table {
  table-layout: fixed;
}

table.flagged-table.table thead th:first-child {
  width: 12%;
}

table.flagged-table.table thead th:nth-child(2) {
  width: 14%;
}

.broker-table.record-update {
  table-layout: fixed;
}

.broker-directory-icon {
  position: relative;
  top: -2px;
}

.maxWidth90Per {
  max-width: 90%;
}

.broker-header-label span.badge {
  color: #383838;
  font-size: 12px;
  font-weight: 400;
  padding: 10px 10px;
  border-radius: 0;
}

.broker-header-label span.badge.high-priority {
  background: #e948531a;
  padding: 0;
}

span.badge.records-updated {
  background: #dfdded;
}

.maxWidth90Per {
  max-width: 90%;
}

.custom-radio {
  position: relative;

  input[type='radio'] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    margin: 3px 0 0;
    z-index: 2;

    &:checked {
      &+label {
        &:before {
          border-color: $primary;
          background: $primary;
        }

        &:after {
          background: $white;
          border-color: $primary;
        }
      }
    }
  }

  label {
    position: relative;
    float: left;
    margin: 0;
    padding: 0 0 0 19px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      border-radius: 50%;
      background: $white;
      z-index: 1;
    }

    &:before {
      width: 14px;
      height: 14px;
      left: 0;
      top: 3px;
      border: 2px solid $white;
    }

    &:after {
      width: 6px;
      height: 6px;
      left: 4px;
      top: 7px;
      border-color: $primary;
    }
  }
}

.customized-checkbox {
  position: relative;

  input[type='checkbox'] {
    position: absolute !important;
    left: 0;
    top: 0;
    opacity: 0;
    margin: 3px 0 0;
    z-index: 2;

    &:checked {
      &+label {
        &:after {
          color: $purple-shade;
        }
      }
    }
  }

  label {
    position: relative;
    float: left;
    margin: 0;
    padding: 0 0 0 28px;

    &:after,
    &:before {
      content: '';
      position: absolute;
      background: $white;
      z-index: 1;
    }

    &:before {
      width: 14px;
      height: 14px;
      border: 2px solid $white;
      left: 0;
      top: 3px;
    }

    &:after {
      left: 1px;
      top: 1px;
      width: 6px;
      height: 6px;
      font-family: 'fontawesome';
      content: '\f00c';
      background: transparent;
      color: $white;
    }
  }
}

.popover-body {
  .attention-item+.attention-item {
    border-top: 1px solid #e4e3e8;
    padding-top: 15px;
  }
}

.no-activity-message {
  padding: 6px 5px;
  border-top: 1px solid #e4e3e8;
  border-bottom: 1px solid #e4e3e8;
}

.modal-dialog.flagged-modal input[type='checkbox']:not(.custom-control-input):before,
input[type='radio']:before {
  border: 1px solid $purple-1;
}

.flagged-modal input[type='radio']:disabled {
  opacity: 0.3;
}

.save-confirmation-modal h5 {
  font-size: 16px;
}

.save-confirmation-modal .modal-header {
  padding: 10px 15px;
}

.text-capitalized {
  text-transform: capitalize;
}

.bright-yellow {
  background: #fdf6df;
  padding: 15px 12px;
}

.bright-yellow a {
  color: #1265d4;
  font-weight: 400;
  font-size: 14px;
  text-decoration: underline;
}

.pagination-dots {
  margin-left: auto;
}

table.project-detail-heading-table {
  table-layout: fixed;
}

.remaining-brokers {
  position: relative;
  max-height: 38px;
}

.remaining-brokers .collapse {
  position: absolute !important;
  z-index: 1;
  background: $white;
  padding: 8px 23px 0px 8px;
  box-shadow: 0px 0px 10px 2px #ccc9;
  border-radius: 8px;
  top: 100%;
  left: 0;
}

.broker-company {
  display: inline-block;
  max-width: 160px;
}

button.eye-button,
button.eye-button:hover,
button.eye-button:focus {
  background: transparent !important;
  border: 0 !important;
  margin-top: 25px;
  box-shadow: none !important;
  outline: 0 !important;
  align-self: flex-start;
  position: relative;
  left: -40px;
  margin-right: -40px;
  z-index: 11;
}

.updated-missing-search select,
.updated-record-search select {
  border-radius: 8px 0 0 8px;
  box-shadow: unset !important;
  font-size: 14px;
}

.sort.input-group span.input-group-text {
  background: $white;
  color: #18171c;
  border-radius: 8px 0 0 8px;
  font-size: 14px;
}

.sort.input-group select {
  font-size: 14px;
}

.updated-missing-search input,
.updated-record-search input {
  font-size: 14px;
  border-radius: 0 8px 8px 0;
}

.bg-grey {
  background-color: $gray-200;
}

button.high-priority-button.btn.btn-outline-secondary {
  border: 0 !important;
  color: #383838 !important;
  font-size: 12px;
  font-weight: 400;
  border-radius: 0;
  line-height: 1;
  background: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding: 10px 10px;
}

.max-w-160px {
  max-width: 160px;
}

.max-w-230px{
  max-width: 200px;
}

.max-w-400px {
  max-width: 400px;
}

span.blue-dot {
  display: inline-block;
  height: 8px;
  width: 8px;
  background: #76729a;
  border-radius: 50%;
}
.frame-io-tabs .tab-ul{
  margin-bottom: 0px !important;
  border-bottom: 1px solid #dedede;
  background-color: $white !important;
}
.frame-io-tabs .tab-ul>a.missing-details-tab {
  background: $purple-shade;
  color: #fff;
  padding: 10px 30px;
  font-size: 14px;
}

.bg-grey-upload {
  background-color: #d8d7e14a;
}

.upload-section {
  border: 1px dashed #b5b5b5 !important;
  margin-bottom: 20px !important;
  border-radius: 8px !important;
  position: relative !important;
}

.upload-section .input-file input {
  width: 100%;
  height: 155px;
  opacity: 0;
}

.upload-section-abs {
  position: absolute;
  top: 50px;
  width: 100%;
}

.bg-grey-upload .progress {
  height: 10px;
}

.upload-section-abs span {
  color: #1d73d2;
}

.input-file {
  z-index: 1;
  position: relative;
}

.img-div {
  width: 200px;
  // height: 163px;
}

.img-div img {
  max-width: 100%;
}

.img-checkbox.form-check {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 111;
}

#project-asset-toggler {
  text-align: left;
  border: 0;
  padding: 10px 30px;
  color: $black !important;
  font-size: 14px;
  box-shadow: none !important;
  border-radius: 0;
  border-right: 5px solid transparent;
}

button.folder-back-btn {
  color: #18171c !important;
  font-weight: 500;
}

.video-main-div>div {
  width: 200px !important;
  height: 105px !important;
}

span.line-through {
  text-decoration: line-through;
}

.timeline {
  .timeline-block {
    position: relative;
    border-right: solid 2px $white;
    float: left;
    font-size: 14px;
    color: #383838;
    padding: 10px;
    margin-bottom: 30px;
    margin-top: 20px;

    span.step-name {
      font-weight: 600;
    }

    &::before {
      position: absolute;
      content: ' ';
      left: 0;
      top: -20px;
      width: 100%;
      height: 1px;
      background-color: #c6c3da;
    }

    &::after {
      position: absolute;
      content: ' ';
      left: 0;
      top: -23px;
      width: 8px;
      height: 8px;
      border-radius: 8px;
      background-color: #c6c3da;
    }

    span {
      font-size: 12px;
    }

    &.editing-draft-1-block,
    &.data-collection-block,
    &.pilot-scheduled-block,
    &.pilot-image-submission-block {
      background-color: #a0f2b6;
      min-width: 190px;
    }

    &.auto-process-block {
      background-color: #f75c5e;
    }

    &.gap-block {
      background-color: #a3bdf0;
    }

    &.review-pending-block {
      background-color: #898989;
    }

    &.editing-draft-2-block {
      background-color: #ffbf04;
    }

    &.gap-block,
    &.editing-draft-1-block,
    &.review-pending-block,
    &.pilot-scheduled-block,
    &.pilot-image-submission-block {
      padding: 10px;

      &::after {
        left: 50%;
      }
    }

    &.auto-process-block,
    &.review-pending-block {
      color: #fff !important;
    }

    &.gap-block {
      min-width: 115px;
    }

    &.review-pending-block {
      min-width: 195px;
      text-align: center;
    }
  }
}

.property-card-detail {
  border: solid 1px $white;

  &:hover {
    border-color: #f39f9f;
  }
}

.annotation-abs {
  position: absolute;
  left: 0;
  top: 0;
}

.outline-none {
  outline: none;
  box-shadow: none !important;
}

.comment-row {
  .edited-comment-card {
    background: #fcfde7;
    padding: 10px;
    margin-top: 10px;
  }
}

.badge-light {
  background-color: #f0f0f5 !important;
}

.version-dropdown {
  background: transparent !important;
  color: #9789d2 !important;
}

.elapsed-times {
  .elasped-time-duration-span+.elasped-time-duration-span:before {
    content: ', ';
    margin-left: -3px;
  }
}

.annotation-image-preview {
  width: 640px;
  height: 360px;
  margin: 0 auto;
}

.pilot-tabs-ul {
  margin: 0 -15px;
}

.pilot-tabs-ul .nav-tabs .nav-link {
  border: 0;
  border-bottom: 2px solid transparent;
  white-space: nowrap;
}

.pilot-tabs-ul .nav-tabs .nav-link.active {
  background: transparent;
  color: initial;
  border: 0;
  border-bottom: 2px solid $purple-1;
}

/*** Pliot portal css***/
.pilot-mark-complete-checkbox {
  padding: 0.5rem 1rem 0.5rem 1.5rem;
}

.accept-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: #c80815
}

.pilot-main-wrapper {
  position: relative;
  // padding-bottom: 113px;
  min-height: 100vh;
}

span.circle-icon {
  height: 13px;
  width: 13px;
  border: 2px solid #ccc;
  display: inline-block;
  border-radius: 50%;
}

.line-height-23 {
  line-height: 23px;
}

.bg-grey-pilot {
  background: #f9f9fb;
}

.pilot-detail-calender .react-datepicker-wrapper {
  width: 100%;
}

.pilot-detail-calender .react-datepicker-wrapper input {
  width: 100%;
  background-clip: padding-box;
  border: 1px solid #c9c7d1;
  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  outline: transparent;
  line-height: 1.5;
}

.select__single-value {
  width: 100%;
}

.progress-span {
  display: inline-block;
  height: 40px;
  width: 40px;
  margin-right: 8px;
}

.add-notes-modal h5 {
  font-size: 16px;
}

.add-notes-modal .modal-body {
  max-height: 60vh;
  overflow-y: auto;
}

.pilot-project-select {
  width: 50%;
}

.pilot-mark-complete-checkbox.d-flex.ml-auto.form-check span {
  white-space: nowrap;
}

.opacity-0 {
  opacity: 0;
}

.opacity-2 {
  opacity: 0.2;
}

.list-style-circle {
  list-style: circle;
}

.flight-modal .react-datepicker-wrapper {
  display: block;
}

.flight-modal .text-danger.font-size-12 {
  text-align: left;
  padding-top: 10px;
}

.select__single-value {
  width: 100%;
}

.progress-span {
  display: inline-block;
  height: 40px;
  width: 40px;
  margin-right: 8px;
}

.add-notes-modal h5 {
  font-size: 16px;
}

.add-notes-modal .modal-body {
  max-height: 60vh;
  overflow-y: auto;
}

.pilot-project-select {
  width: 50%;
}

.pilot-mark-complete-checkbox.d-flex.ml-auto.form-check span {
  white-space: nowrap;
}

.deal-table {
  border-collapse: separate;
  border-spacing: 0 15px;
}

.deal-table td {
  border: 0;
}

.deal-table tr td {
  font-size: 13px;
  border-top: 0;
}

.width-90 {
  width: 90%;
}

.sales-summery-price {
  border-radius: 4px;
  border: 1px solid #cac7db;
}

.bg-about-this-deal {
  background-color: #e9eaef99;
}

.about-this-deal {
  background-color: #e9eaef;
}

.base-package-color {
  background-color: #5650821a;
}

.payment-status {
  background-color: #e2e2ed;
}

.package-add-ons {
  min-height: 100vh;
}

.project-details-spp .modal-content {
  border-radius: 0;
  border: 0;
  min-height: 100vh;
}

.attachment-overflow {
  max-height: 395px;
}

.sales-full {
  min-height: calc(100vh - 59px);
}

.dropdown-menu.show {
  display: block;
  max-height: 200px;
  overflow: auto;
}

.customer-overflow {
  max-height: 240px;
  overflow-y: auto;
}

.sales-upload .bg-grey {
  background: transparent;
}

.sales-upload .min-w-320 {
  min-width: 340px;
  overflow-x: hidden;
}

.sales-upload .min-w-320 .col-md-12 {
  padding: 0;
}

.sales-upload {
  padding-top: 10px;
}

.h-400px {
  min-height: 400px;
}

.date-pick .react-datepicker-wrapper {
  display: block;
}

.portal-dropdown-new .dropdown-menu.show {
  background: $white;
  border: 1px solid #00000033;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px #3c40434d, 0px 2px 6px 2px #3c404326;
  overflow-x: hidden;
  padding-top: 0;
  position: relative;
  width: 270px;
  max-height: unset;
}

.portal-dropdown-new .dropdown-item.active,
.portal-dropdown-new .dropdown-item:active {
  color: initial;
  text-decoration: none;
  background-color: unset;
}

.portal-dropdown-new .dropdown-menu.show button.dropdown-item {
  width: 50%;
  font-size: 12px;
  padding: 6px;
  border-radius: 8px;
}

.portal-dropdown-new .menu-main-button {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.portal-dropdown-new .menu-main-button:hover,
.portal-dropdown-new .menu-main-button:active {
  background-color: #3c404314;
  color: $purple-1;
}

.portal-dropdown-new .dropdown-item:hover,
.portal-dropdown-new .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f9f9fb;
}

span.logo-name {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
}

span.logo-name img {
  width: 40px;
  height: 40px;
  // border-radius: 50%;
  margin-bottom: 5px;
}

.was-validated input.form-control:invalid,
input.form-control.is-invalid {
  background-image: none;
}

.projects-review-scroll {
  max-height: 430px;
  overflow-y: auto;
  overflow-x: hidden;
}

.requirement-height {
  height: calc(100vh - 85px);
}

.country-select {
  border: 0px none;
  background: $white;
  padding: 6px 10px;
  position: relative;
  width: 100%;
  color: rgb(51, 51, 51);
  border-radius: 4px;
  box-shadow: #c9c7c7 0px 0px 0px 1px inset;
  line-height: 20px;
}

.date-filter-bd .react-datepicker__tab-loop {
  display: inline-block;
}

.spinner-button {
  text-align: center;
  width: 96px;
  display: inline-block;
  padding: 0 0px;
}

.pagination-dots button.active.btn.btn-secondary {
  z-index: 0;
}

.disable-tab {
  pointer-events: none;
  opacity: 0.5;
}

.invite-badge {
  background-color: #BBB8D4 !important;
  font-size: 12px !important;
  color: $black !important;
  padding: 7px 18px !important;
  margin-left: 10px !important;
  display: inline-block !important;
  font-weight: 500 !important;
}

.invite-available {
  border: 1px solid #42B15B;
  background-color: #EAFCEE !important;
  color: #42B15B !important;
}

.grey-star {
  color: #c1bfbf;
}

.pilot-invitation-status {
  display: block;
  padding-top: 2px;
}

.editor-invite-prompt-modal {
  .modal-content {
    width: 100%;
  }
}

#screenWithAnnotation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#Progress_Status {
  width: 50%;
  background-color: #ddd;
}

#myprogressBar {
  width: 2%;
  height: 20px;
  background-color: #4CAF50;
}

.video-player-videoms {
  background-color: #0F1014;

  .timeline {
    background-color: #20222A;
    padding: 0px 10px;
    margin-bottom: 10px;
    border-radius: 0px 0px 5px 5px;

    select {
      background: #3F414D;
      border: none;
      color: $white;
      padding: 1px 5px;
      border-radius: 10px;
      font-size: 11px;
      cursor: pointer;
    }
  }

  .controls {
    padding: 0px 0px 5px 0px;
  }

  .comments-section {
    background-color: #E8E8E8;
  }

  .video-icons {
    font-size: 16px;
    color: $white;
  }

  .comment-input-box {
    background-color: #30333C;
    // padding: 10px;
    margin-bottom: 15px;
    border-radius: 5px;

    .comment-input {
      background-color: #4e4e4e;
      padding: 5px 10px;
      border-radius: 5px;

      .c-input {
        border: none;
        background-color: transparent;
        color: $white !important;
      }

      .c-input::placeholder {
        color: gray !important;
      }
    }
  }

  #videotag,
  #videoPlayerScreenDiv {
    width: 100%;
    height: calc(100vh - 226px);
    position: absolute;
    top: 0;
    left: 0;
  }
  #videoWithAnnotation, #screenshotImg {
    height: calc(100vh - 226px);
    position: relative;
  }
  #videotag {
    z-index: 0;
  }

  .annotation-menu {
    padding: 25px 25px 0px 25px;
    background-color: #000000d6;
    color: $white;
    max-height: 400px;

    .dropdown-item {
      padding: 0px;
      color: $white;
      text-align: center;
      margin-right: 10px;
      min-width: 40px;
      border-radius: 5px;

      &:hover {
        background-color: grey;
      }
    }
  }

  .konvajs-content {
    z-index: 999;
  }

  .activeAnnotations {
    background-color: grey !important;
  }

  .comments-section {
    background-color: #E8E8E8;
    color: $black-200;

    .comment-list {
      height: calc(100vh - 97px);
      overflow: auto;

      .spinner {
        position: fixed;
        top: 50%;
        left: 85%;
      }
    }
  }

  .screenshot-dot {
    cursor: pointer;
    position: absolute;
    background: #2196f3;
    color: $black;
    border-radius: 50%;
    font-size: 8px;
    height: 10px;
    width: 10px;
    display: block;
    text-align: center;
  }

  .annotation-menu.dropdown-menu.show {
    left: -265px !important;
  }

  .comment-emoji {
    .dropdown-menu.show {
      max-height: 400px !important;
    }
  }

  .video-p-size {
    height: 60vh;
    width: 100%;
    object-fit: fill;
    position: absolute;
  }
}

.review-link {
  @include show-ellipses-mixin;
  display: block;
  padding: 10px 0px;
}

.asset-copy-link {
  // background: #c7ccd1;
  border: 1px solid #c7ccd1;
  padding: 5px;
  width: 90%;
}

.copy-asset-icon {
  font-size: 18px;
}

.login-model {
  max-width: 400px !important;

  p {
    color: rgb(125, 130, 156);
  }
}

.first-letter-badge {
  padding: 2px 8px;
  color: $white;
  border-radius: 50%;
  margin-right: 10px;
  font-size: 16px;
  text-transform: capitalize;
}

.modal-content {
  .modal-header {
    .modal-title {
      font-size: 16px !important;
    }
  }
}

.user-points-name {
  background: #2196f3;
  color: $black;
  border-radius: 50%;
  font-size: 9px;
  height: 13px;
  width: 13px;
  display: inline-block;
}

.video-actions .dropdown-item:focus {
  background-color: transparent;
}

.video-version {
  .button-text {
    @include show-ellipses-mixin;
    max-width: 350px;
    text-overflow: ellipsis;
    position: relative;
    top: 3px;
  }

  .dropdown-menu.show {
    padding: 0px;
    width: 250px;
    padding: 0px;
  }

  ul {
    list-style-type: none;
    padding: 0px 0px 0px 15px;

    li {
      padding: 5px;

      &.active-version {
        background-color: $purple-2;

        a {
          color: $white;
        }
      }

      &:hover {
        background-color: $purple-2;
        color: $white;

        a {
          color: $white;
        }
      }
    }
  }

  .dropdown-toggle {
    background: transparent;
    border: 0px;
  }

  .show>.btn-secondary.dropdown-toggle {
    background: transparent !important;
    border: 0px !important;
  }

  .Collapsible__trigger {
    display: inline-block;
    width: 100%;
    font-size: 13px;
    font-weight: 600;
    padding: 10px;

    &:hover {
      background-color: $purple-2 !important;
      color: $white !important;
    }
  }

  .dropdown-toggle::after {
    margin-right: 10px !important;
  }
}


.sync-button {
  svg {
    margin-left: 5px;
    font-size: 10px !important;
  }

  .rotate {
    animation: rotation 8s infinite linear;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
    }
  }
}

.review-checkbox {
  position: relative !important;
  top: 2px;
}

.discount-line-item {
  padding-left: 20px;
  color: #e2b128;
  font-size: 10px;
}

.line-item-name {
  @include show-ellipses-mixin;
  max-width: 175px;
  display: inline-block;
  font-weight: 500;
}

.max-width-500px {
  max-width: 500px !important;
}

.deal-name-btn {
  position: relative;
  top: 6px;
}

.show-elipsis-100 {
  @include show-ellipses-mixin;
  max-width: 20rem;
  display: inline-block;
}

#collabsible {
  text-align: left;
  border: 0;
  background: $gray-500 !important;
  color: $black !important;
  font-size: 15px;
  box-shadow: none !important;
  border-radius: 0px;
}

.video-player-comment {
  line-break: anywhere;
}

.show-contacts-list {
  max-height: 225px;
  overflow-y: auto;
  overflow-x: hidden;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.show-elipsis {
  @include show-ellipses-mixin;
  display: inline-block;
}

.no-access-401 {
  height: 100vh;
  background-color: $white;
}

.pilot {
  height: 50px;

  .active.nav-link {
    background: $purple-1 !important;
    color: $white !important;
  }

  .nav-link {
    background: $purple-4 !important;
    color: $black !important;
    border-radius: 3px !important;
    padding: 7px 15px !important;
    margin: 8px 5px !important;
    border-bottom: 0px !important;

    &hover {
      border-bottom: 0px !important;
    }
  }
}

.additional-notes-height {
  max-height: 240px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}

.pilot-project-select {
  .option-text {
    @include show-ellipses-mixin;
    width: 80%;
    text-overflow: ellipsis;
  }
}

.Toastify__toast-body {
  text-transform: lowercase !important;
}

.Toastify__toast-body:first-letter {
  text-transform: capitalize !important;
}

.record-deals {
  position: absolute;
  top: 23px;
  display: flex;
  align-items: center;
}

.role {
  @include show-ellipses-mixin;
  width: 90%;
}

.StripeElement {
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.v-loading-container {
  height: calc(100vh - 250px);
  background: $black;
  width: 100%;
}

.v-loading-image-container {
  height: calc(100vh - 250px);
}

.property-type {
  @include show-ellipses-mixin;
  max-width: 95px;
}

.image-gallery-content {
  .image-gallery-slide {
    height: calc(100vh - 220px) !important;
    .image-gallery-image {
      // height: calc(100vh - 220px) !important;
      height: 100% !important;
      max-height: 100% !important;
    }
  }
}

.image-gallery-left-nav{
  .image-gallery-svg{
    height: 56px !important;
    width: 30px !important;
  }
} 

.image-gallery-right-nav{
  .image-gallery-svg{
    height: 56px !important;
      width: 30px !important;
  }
}
.close-btn{
  img{
    filter: invert(1);
    height: 30px;
    background: #dedede;
    border-radius: 50%;
  }
    z-index: 9;
    right: 20px;
    top: 20px;
}
.project-name-ellipses{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;
  display: inline-block;
}
.form-control:focus{
  box-shadow: none !important;
}

.cb-multiple-fields{
  display: flex;
  .form-group{
    margin-right: 10px;
    &:nth-last-child(2) {
      width: 100px;
   }
    &:last-child  {
      width: 100px;
    margin-right: 0px !important;
    }
  }
}
.bronze-button{
    font-size: 13px !important;
    border-radius: 20px !important;
    font-weight: 500 !important;
    height: 35px;
}
.crexi-bronze-cancel{
  @extend .bronze-button;
    background-color: #F0F0F0 !important;
    color: #000 !important;
    border: none !important;
    width: 80px;
}
.crexi-previous-button{
  @extend .bronze-button;
  width: 180px;
}
.crexi-save-button{
  @extend .bronze-button;
  width: 180px;
}
.crexi-cancel-button{
  @extend .bronze-button;
  width: 180px;
  background-color: $green-2 !important;
  color: $white !important;
  border: none !important;
  &:hover{
  background-color: $green-2 !important;
  color: $white !important;
  }
}
.upload-chapter-logo{
  padding-top:   calc(100vh - 685px)  ;
}
.upload-chapter-image{
  padding-top: calc(100vh - 560px);
.logo-height{
  height: 50px;
}
}
.portal-name{
    background: #DBDBDB;
    height: 30px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding-left: 15px;
    font-weight: 500;
    color: $purple-shade;
    font-size: 12px;
    margin-bottom: 10px;
}
.active-project-expandable{
  .react-bootstrap-table{
    .table{
      margin-bottom: 0px;
    }
  }
}
.notification-dot{
  height: 10px;
  width: 10px;
  display: inline-block;
  background: $danger;
  border-radius: 50%;
  position: absolute;
  top: 12px;
  border: 2px solid $white;
}
.input-group-text{
  border-radius: 0.25rem 0rem 0rem 0.25rem !important;
}
.detail-table {
  &.table-bordered{
    border: 0px !important;
  }
  td:nth-child(1){
    border-left: 0;
  }
  td:last-child{
    border-right: 0;
  }
}

.portal-sidebar-list{
 .tab-ul {
  .nav-link{ 
    background: #E8E8E8;
    color: $black !important;
  }
}
}
.status-select{
  border: none;
  background: $white;
  &:focus-visible{
    border: none;
    background: $white;
    outline: none;
  }
}
.table-header-list{
  margin-top: 15px;
  thead{
    background: #c8c7d2;
    color: #302e38;
    th{
      padding: 6px 0.75rem !important;
      font-size: 12px;
  }
  }
  tr{
    td{
      padding-bottom: 10px !important;
      padding-top: 10px !important;
    }
  }
}
.project-asset-list{
  height: 200px;
  overflow: auto;
  background-color: $white;
  .nav-item{
    .nav-link:hover{
      background-color: #e8e8e8;
    }
  }
}
.active-state{
  background-color: #F5F4FE !important;
  border-right: 5px solid $primary !important;
}
.available-review-link{
  background: #FAF0D7;
    padding: 1px 10px;
    margin-left: 15px;
    border-radius: 4px;
    font-size: 12px;
}

.upload-image-btn{
  height: 50px;
    width: 200px;
    color: #665EA0 !important;
    margin-bottom: 0px !important;
    &:hover{
      background-color: transparent !important;
      color: #665EA0 !important;
    }
}

  input[type="radio"], input[type="checkbox"]{
    height: 15px;
    width: 15px;
    accent-color: #565374;
  }
  .map-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .credintials-popover{
    .popover.show{
      max-width: 100% !important;
    }
  }

  .maxWidth100Per {
    max-width: 100%;
  }

  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}