$blue: #47bcba !default;
$blue-light: #6ec7ff !default;
$blue-light-300: #baf1fe !default;
$dark-slate-blue: #003e55 !default;
$lapis-blue: #0a516c !default;

$indigo: #6610f2 !default;
$purple-shade: #7870b5;

$purple-1: #565082 !default;
$purple-2: #9789d2 !default;
$purple-3: #6c6b70 !default;
$purple-4: #d8d3ee !default;
$purple-5: #f9f8fc !default;
$purple-6: #9a8fc7 !default;
$purple-7: #e8e6f4 !default;

$pink: #e83e8c !default;
$red: #fe2712 !default;
$orange: #fd7e14 !default;
$yellow: #f8cb51 !default;
$green: #6ece5c !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$green-2: #64B728 !default;

$white: #fff !default;
$gray-100: #f9f8fc !default;
$gray-200: #eae6f4 !default;
$gray-300: #e4e3e8 !default;
$gray-400: #c9c7d1 !default;
$gray-500: #e4e3e8 !default;
$gray-600: #f4f4f4 !default;
$gray-700: #c7ccd1 !default;
$grey-800: #e8e8e8;

$black: #000 !default;
$black-100: #18171c !default;
$black-200: #302e38 !default;
$black-300: #484554 !default;
$black-400: #c7ccd1 !default;
$black-500: #f2f0f8 !default;

$primary: $purple-1 !default;
$secondary: $purple-2 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $black-100 !default;
$scrollbar-color: #f8f8f8;
$gray: #81838A !important;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
);

@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

$body-bg: $white !default;
$body-color: $gray-900 !default;

$bg-primary: $blue !default;
// Links
//
// Style anchor elements.

$link-color: theme-color('primary') !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;
$border-radius-8px: 8px !default;
$font-weight-500: 500;