.w-70 {
    width: 70% !important;
}

.text-danger {
    color: $danger !important
}

.flagged-modal {
    .modal-header {
        border-bottom-color: #c2c6c9;
        border: 0;

        h5 {
            font-size: 15px;
            color: #18171c;
        }
    }

    .modal-body {
        padding-top: 0;

        .user {
            margin: 0 -1rem;
            background: #f1f1f5;
            padding: 15px 15px;

            p {
                color: #18171c;
                font-weight: 600;
                font-size: 14px;
            }

            span {
                color: #383838;
                font-size: 14px;
            }
        }

        h6 {
            color: #18171c;
            font-size: 14px;
        }

        label.form-check-label {
            color: #383838;
            font-size: 14px;
        }

        .form-check {
            padding: 0;
        }
    }

    .priority-1 {
        background-color: #E948531A;
    }

    .priority-2 {
        background-color: #FF77001A;
    }

    .priority-3 {
        background-color: #F4BE091A;
    }

    .add-note {
        margin-top: 12px;
    }

    .add-note textarea {
        min-height: 90px;
        font-size: 13px;
    }

    .charecter-counter {
        top: -8px;
        font-size: 10px;
        position: relative;
        color: rgba(56, 56, 56, 0.65);
    }
}

.form-control {
    &.email-not-found::placeholder {
        color: $danger !important;
    }

    &.email-not-found:-ms-input-placeholde {
        color: $danger !important;
    }

    &.email-not-found::-ms-input-placeholder {
        color: $danger !important;
    }
}

tr.tr-priority-1 {
    background: #E948531A;

    &.text-danger {
        td {
            a {
                color: $danger;
            }
        }
    }
}

tr.tr-priority-2 {
    background: #FF77001A;

    &.text-danger {
        td {
            a {
                color: $danger;
            }
        }
    }

}

tr.tr-priority-3 {
    background: #FFE2001A;

    &.text-danger {
        td {
            a {
                color: $danger;
            }
        }
    }

}

.note-tr {
    td {
        border-top: 0;
        padding: 0 8px;

        p {
            color: rgba(24, 23, 28, 0.6);
            margin-bottom: 0;
        }
    }
}

tr.note-tr td {
    border: 0;
    padding-top: 0;
}