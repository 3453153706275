@import './variables.scss';
@import './custom.scss';
@import './brokers-directory.scss';
@import './asana-status-styles.scss';
@import './aws-folder-styles.scss';
@import "./responsive.scss";
@import "./vidpitch.scss";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-primary {
  background-color: $bg-primary !important;
}

.btn-link {
  color: $primary !important;
}

a {
  color: $primary;

  &:hover {
    color: $dark;
  }
}

#root {
  height: 100%;
}
header {
  box-shadow: 0px 3px 6px #00000029 !important;
  z-index: 99 !important;
  position: relative;
}
.main-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;

  header {
    box-shadow: 0px 3px 6px #00000029;

    .navbar {
      padding: 0px;
    }

    .navbar-nav {
      .nav-item {
        .nav-link {
          padding: 0rem 1.5rem;
          height: 70px;
          line-height: 70px;
          font-weight: 600;

          &:hover {
            color: $dark;
            background-color: $white;
          }
        }
      }

      .user-dd {
        padding: 0px;
        min-width: 280px;
        border: none;
        padding-bottom: 0.5rem;
        box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);

        .btn {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          display: block;
          width: 100%;
          text-align: left;
          color: $gray-600;

          &:hover {
            background-color: $gray-200;
            border-radius: 0px;
            text-decoration: none;
          }
        }
      }
    }
  }

  .left-sidebar {
    height: calc(100% - 70px);
    position: fixed;
    z-index: 9;
    top: 70px;
    left: 0;
    width: 240px;
    background-color: $gray-900;

    .navbar-nav {
      .nav-item {
        .nav-link {
          font-weight: 600;

          i {
            color: $primary;
          }

          &:hover {
            i {
              color: $white;
            }
          }
        }

        .active.nav-link {
          color: $white;

          i {
            color: $white;
          }
        }
      }
    }
  }
}

.no-auth-main-container {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: $gray-300;
  height: 100%;

  .form-signin {
    width: 100%;
    max-width: 400px;
    padding: 15px;
    margin: auto;
  }
}

.active-project-icon {
  border: 1px solid #565374;
  border-radius: 20px;
  padding: 6px;
  height: 25px;
  width: 25px !important;
  margin-right: 6px;
  color: #565374;
}

.editor-comment-timestamp {
  position: relative;
  left: 64%;
}

.z-index-99{
  z-index: 99;
}
.plan-select-button{
  background-color: #D6D3EF !important;
  border: none;
  color: $purple-shade;
  font-size: 12px;
  &:hover{
    background-color: #D6D3EF !important;
    border: none;
    color: $purple-shade;
  }
}
.plan-select-active{
  font-size: 24px;
  color: $primary;
  
}
.crexi-bronze-content{
  height: calc(100vh - 175px);
  overflow-x: hidden;
  overflow-y: auto;
}
.crexi-bronze-checkbox{
  height: 17px;
  width: 17px;
}
.crexi-bronze-input{
  height: 36px;
  // margin-top: 5px;
  background-color: #F7F7F7;
  font-size: 14px;
}
.crexi-bronze-label{
  font-size: 14px;
    position: relative;
    color: #666666;
    // left: 40px !important;
}
.crexi-bronze-custom-label{
  font-size: 12px;
  position: relative;
  color: #666666;
  position: relative;
  color: #666666;
  border: none;
  left: 20px;
  bottom: 1px;
}
.crexi-bronze-checkbox{
  appearance: none;
}
.form-check .crexi-bronze-checkbox:checked {
  background-color: $primary;
  border-color: $primary;
}

.crexi-bronze-checkbox:checked[type=checkbox] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E");
}

input[type="checkbox"]{
border: 1px solid #7D7D7D;
border-radius: 4px;
}
.desc-arrow{
  color: #74D9AA;
  font-size: 16px;
  margin-top: 3px;
}
.crexi-bronze-fields{
  position: relative;
  input[type="checkbox"]{
    position: absolute;
  }
  label{
    position: relative;
    left: 22px;
    color: #666666;
    font-size: 12px;
    bottom: 1px;
  }
}
.crexi-bronze-empty-map-preview{
  position: absolute;
  left: 50%;
  top: 50%;
}
.seprator{
  border-left: 1px solid #e3e3e3;
  height: 28px;
}
.crexi-link{
  color: #1265D4;
    text-decoration: underline;
}
.upload-text-bz{
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 15px;
  color: #665EA0;
  cursor: pointer;
}
.tab-button-editor{
  font-size: 12px;
  background: $white;
  color: #000;
  border-radius: 20px;
  border: 2px solid #dbdbdd;
  font-weight: 600;
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  &.isactive{
    color: $secondary;
    border: 2px solid $secondary;
    background-color: #E8E5FD;
  }
}
.editor-table{
  height: calc(100vh - 230px);
  overflow: auto;
}

.project-details-bronze{
  width: 50%;
  max-width: 50%;
  .bronze-confirmation{
    border: 1px solid #dedede;
    width: 260px;
    // height: 185px;
    padding: 15px 0px;
    border-radius: 10px;
    background-color: #F7F7F7;
    hr{
      margin: 10px 0px !important;
    }
  }
}
.btn-primary.disabled,
.btn-primary:disabled,
.btn-secondary.disabled, 
.btn-secondary:disabled{
  cursor: not-allowed;
}

.skill-checkbox-ui{
  margin-bottom: 10px;
}
.broker-name-mngmnt{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
  font-size: 14px;
}
.description-h1{
  h1{
    font-size: 14px;
  }
}

.rendred-notes{
  max-height: 150px;
  overflow: auto;
  margin-bottom: 15px;
}

.bg-grey-light{
  background: #f5f5f5;
}

.brokers-content{
  .nav-tabs {
    border: 0;
    .nav-link{
      padding: 2px;
      border: 0;
      border-bottom: 3px solid trensparent;
      margin-right:18px;
      color: $gray;
      cursor: pointer;
      font-weight: 600;
    }
    .nav-link.active, .nav-tabs .nav-item.show .nav-link{
      color: $primary;
      border: 0;
      border-bottom: 3px solid $primary; 
      color: $dark !important;
      background: #F5F5F5;
    }
  }
}

.broker-card{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 16px #0000001F;
  border-radius: 8px;
  opacity: 1;
}
.ellipsis{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.card-checkbox{
width: 119px;
height: 36px;
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #E6E6E6;
border-radius: 4px;
opacity: 1;
bottom: 3px;
position: relative;
input{
  position: relative;
  margin-left: 2px;
  margin-right: 19px;
  top: 4px;
  left: 10px;
}
}
.admin-icon{
  position: absolute;
  left: 20px;
  top: 20px;
  padding: 40px;
  background: #fff;
  border: 1px solid #707070;
  border-radius: 100%;
  height: 120px;
  width: 120px;
  object-fit: cover;
  svg{
    font-size: 56px;
    left: -6px;
    top: -9px;
    position: relative;
    color: #BFBFBF;
  }
}

.do-not-checkbox{
  input{
    top: 3px;
    margin-right: 10px !important;
  }
  label{
    color: $danger;
  }
}

.disabled-ui{
    opacity: 0.2;
    pointer-events: none;
}

.mapbox-button {
  bottom: 50px;
  color: #fff;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  right: 0;
  text-align: center;
}

.sample-video-btn {
  .modal-title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}}

.airtable-embed{
  background: transparent;
  border: 1px solid #ccc;
  height: calc(100vh - 65px)
}
.shotlist{  
   ul {
    list-style-type: none;  
    padding: 0; 
    margin: 0;  
    font-family: Arial, sans-serif;
    li{
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0px !important;
      }
    }
   
  }
}