@import './variables.scss';

span.in-progress-badge {
  color: #fb5779;
  background: #f9f0e6;
  border: 1px solid #e34768;
}

span.not-started-badge {
  color: #084426;
  background: #19db7e;
  border: 1px solid #0f804a;
}

span.project-on-hold-badge {
  color: #6b001d;
  background: #ff93af;
  border: 1px solid #dd6a88;
}

span.in-process-badge {
  color: #fff;
  background: #e34768;
  border: 1px solid #b63b55;
}

span.faa-delay-badge{
  color: #3c5007;
  background: #ace60f;
  border: 1px solid #5b790a;
}

span.weather-delay-badge {
  color: #fff;
  background: #5a7896;
  border: 1px solid #405870;
}

span.scheduled-badge{
  color: #4d3400;
  background: #ffa800;
  border: 1px solid #4d3400;
}

span.rescheduled-badge{
  color: #331a00;
  background: #ff7511;
  border: 1px solid #331a00;
}

span.awaiting-data---imagery-badge{
  color: #574500;
  background: #ffd100;
  border: 1px solid #574500;
}

span.reshoot-images---video-badge{
  color: #3c5007;
  background: #ace60f;
  border: 1px solid #3c5007;
}

span.in-production-badge{
  color: #00423f;
  background: #00d4c8;
  border: 1px solid #00423f;
}

span.awaiting-client-feedback--1st-edits-badge{
  color: #02485a;
  background: #48dafd;
  border: 1px solid #02485a;
}

span.awaiting-client-feedback--2nd-edits-badge{
  color: #fff;
  background: #0064fb;
  border: 1px solid #064bb3;
}

span.awaiting-client-feedback--3rd-edits-badge{
  color: #fff;
  background: #6457f9;
  border: 1px solid #31279b;
}

span.awaiting-client-approval-badge{
  color: #fff;
  background: #9f46e4;
  border: 1px solid #732da9;
}

span.completed-badge {
  color: #570057;
  background: #ff78ff;
  border: 1px solid #570057;
}

span.published-badge {
  color: #fff;
  background: #ff4ba6;
  border: 1px solid #b33575;
}

span.published-badge {
  color: #fff;
  background: #405870;
  border: 1px solid #24303c;
}


span.final-ready-badge {
  color: #298ee5;
  background: #e5f3ff;
  border: 1px solid #298ee566;
}

span.in-review-badge {
  color: #7041b1;
  background: #eee4fc;
  border: 1px solid #7041b166;
}

feSpecularLighting.missed-edit-received-badge,
feSpecularLighting.ready-for-review-badge,
feSpecularLighting.edits-received-badge {
  color: #38b9d0;
  background: #ebfcff;
  border: 1px solid #38b9d066;
}