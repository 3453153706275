@import './variables.scss';

// ----------------------------------- VIDTECH CSS START ------------------------------------------------
.vidTech {
    .video-section {
        background-color: #F9F9FB;
    }
}

.vidTech {
    video::-webkit-media-controls {
        display: none !important;
    }
}

.StripeElement {
    padding: 10px 12px;
    width: 100%;
    color: #32325d;
    background-color: $white;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}


table.vidtech-table td {
    font-size: 14px;
    padding: 0.75rem 0.55rem;
}

table.vidtech-table thead {
    color: #ededed;
    background: #565374;
    text-transform: uppercase;
    position: sticky;
    top: 0;
    z-index: 1;
}

table.vidtech-table thead th {
    font-weight: 500;
    font-size: 13px;
    padding: 13px 0.75rem;
}

table.vidtech-table {
    background: $white;
}

.vidtech .react-bootstrap-table {
    height: calc(100vh - 175px);
    overflow: auto;
}

table.vidtech-table tr:last-child td {
    border-bottom: 1px solid #e4e3e8;
}

.vidtech-input {
    border-radius: 20px !important;
}

.vidtech-datepicker {
    border-radius: 20px !important;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid #c9c7d1;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.vidpitch-stepper-step {
    border: 1px solid #707070;
    border-radius: 26px;
    padding: 5px 12px 5px 5px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-right: 30px;

    &.active {
        background: #E8E5FD;
        color: #665EA0;
    }

    span {
        width: 30px;
        height: 30px;
        background: #665EA0;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        color: $white;
    }
}

.vm-suggession {
    font-size: 14px;
    background-color: #FDF6ED;
}

.vidpitch-height {
    min-height: calc(100vh - 56px);
    height: calc(100vh - 56px);
    overflow: auto;
}
.crexi-bronze-height{
    min-height: calc(100vh - 60px);
    height: calc(100vh - 60px);
    overflow: auto;
}
.temp-settings {
    background-color: #E8E5FD;
    h6 {
        margin: 0px;
        font-weight: 400;
        color: #4D4965;
    }
}

.template-img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.template-img-2 {
    width: 145px;
    height: 100px;
    margin-bottom: 10px;
    border-radius: 5px;
}

.template-checkbox {
    position: absolute !important;
    top: 7px;
    left: 7px;
}

.temp-button {
    border-radius: 30px !important;
    font-size: 14px !important;
    margin-right: 10px;
}

.temp-star {
    position: relative;
    bottom: 3px;
}

.vidpitch-stepper-step::before {
    content: '';
    position: absolute;
    left: 205px;
    width: 31px;
    height: 2px;
    background-color: #665EA0;
}

.image-req {
    background: #EFEDFF;
    padding: 30px 15px;
    border-radius: 20px;
    max-height: 535px;
    overflow: auto;
}

.height-100px {
    height: 100px;
}

.v-address-card {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
}

.v-map-icon {
    border: 2px solid #ddd;
    padding: 5px 11px;
    font-size: 17px;

}

.v-active-image {
    border: 4px solid #fa755a;
}

.v-background-color {
    background: transparent linear-gradient(180deg, $white 0%, #DCD9F4 81%, #A9A4D0 100%) 0% 0% no-repeat padding-box;
}

.vidtech {
    .caret {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 2px;
        vertical-align: middle;
        border-top: 4px dashed;
        border-top: 4px solid \9;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        cursor: pointer;
    }

    .dropup .caret,
    .navbar-fixed-bottom .dropdown .caret {
        border-top: 0;
        border-bottom: 4px dashed;
        border-bottom: 4px solid \9;
        content: "";
        cursor: pointer;
    }
}

.v-trancate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.react-datepicker-popper {
    z-index: 9 !important;
}

.v-calender-error {
    position: absolute;
    top: 6.2rem;
    font-size: 10px;
}

.vidTech {
    .video-section {
        background-color: #F9F9FB;
    }

    video::-webkit-media-controls {
        display: none !important;
    }
}

.object-fit-cover {
    object-fit: cover;
}

.object-fit-contain {
    object-fit: contain;
}

.vit-video-download {
    height: calc(100vh - 49px);

    video {
        width: 100%;
        height: calc(100vh - 150px);
    }
}

.vidTech-note {
    position: relative;
    bottom: 7px;
    font-weight: 500;
    color: $black-300;
}

// ----------------------------------- VIDTECH CSS END ------------------------------------------------