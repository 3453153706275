.main-folder {
  border-radius: 4px;
}

.video-review-head .table th,
.video-review-head .table td {
  padding: 0 0.75rem;
}

.video-review-head {
  background: #f0f0f5;
}

.dotted-2px {
  border-top: 2px dotted rgba(0, 0, 0, 0.1);
}

.mark-complete-checkbox label.form-check-label {
  font-size: 14px;
  font-weight: 500;
  color: #565082;
}

.mark-complete-checkbox label.form-check-label input.form-check-input {
  height: 15px;
  width: 15px;
  margin-bottom: 10px;
  top: -2px;
}

.video-review-sidebar {
  border-top: solid 1px #fff;
  width: 100%;
}

.frame-io-tabs .tab-ul a.active {
  background: #7870b5;
  color: #fff;
  &:hover{
    color: $black;
  }
}

.frame-io-tabs .tab-ul a,
.frame-io-tabs .tab-ul ul.nav p {
  color: #ffffffb3;
  font-size: 14px;
  background: $white;
  color: #515057;
  padding: 0.7rem 1.5rem;
  margin-bottom: 0;
}

.frame-io-tabs .tab-ul ul.nav p {
  background: #d7d6e0;
  cursor: pointer;
}

.frame-io-tabs .tab-ul {
  background: #7870b5;
  color: #fff;
  margin-bottom: 1px;
}

.frame-io-tabs .tab-ul ul.nav a.active {
  background: #bab7d3;
  color: #18171c;
}

.frame-io-tabs .tab-ul ul.nav a {
  position: relative;
  // border-bottom: 1px solid #bbb8d4;
  padding: 0.7rem 1.5rem;
}

.frame-io-tabs .tab-ul ul.nav a.active:after {
  display: block;
  content: '';
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #7870b5;
  position: absolute;
  left: 8px !important;
  top: 15px !important;
}

.blue-color-link {
  color: #2b83fa;
}

.border-grey-top {
  border-top: 1px solid #c6c3da;
}

.video-review-head table td.border-td {
  border-left: 1px solid #c6c3da;
}

.asset-search {
  background: #d7d6e0;
  padding: 0px;

  .form-control {
    height: 38px !important;
    border-radius: 0px;
  }
}

.video-review-sidebar a {
  position: static !important;
}

.video-review-sidebar a.active:after {
  left: 18px !important;
  top: 20px !important;
  right: 0 !important;
}

.folder-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}